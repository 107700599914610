import React from 'react'
import { makeStyles } from '@material-ui/styles'

import { ClickAwayListener, Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    zIndex: '1',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    borderRadius: '1rem',
    width: '17rem',
    top: '100%',
  },
  entry: {
    '&:hover': {
      cursor: 'pointer',
    },
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0.5rem',
  },
}))

const EntriesDialog = props => {
  const classes = useStyles()
  const { open, setOpen, values, addToRedux } = props
  const handleClickAway = () => {
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className='rounded-full' style={{ marginLeft: '1rem', backgroundColor: 'white' }} >
        {open &&
        <div style={{ display: 'flex' }}>
          <Paper className={classes.paper}>
            {
              values.map((value, i) => (
                i < 10
                  ? <Paper className={classes.entry} onClick={() => addToRedux({ name: 'case_id', value: value.case_id })} key={Math.random()}>
                    <Typography>{value.case_id}</Typography>
                    <Typography>{value.report_end.substring(0, 10)}</Typography>
                  </Paper>
                  : ''
              ))
            }
          </Paper>
          <Paper style={{ left: '69rem' }} className={classes.paper}>
            {
              values.map((value, i) => (
                i >= 10
                  ? <Paper className={classes.entry} onClick={() => addToRedux({ name: 'case_id', value: value.case_id })} key={Math.random()}>
                    <Typography>{value.case_id}</Typography>
                    <Typography>{value.report_end.substring(0, 10)}</Typography>
                  </Paper>
                  : ''
              ))
            }
          </Paper>
        </div>
        }
      </div>
    </ClickAwayListener>
  )
}

export default EntriesDialog
