import React from 'react'

import './pdfStyles.css'

const PdfViewer = props => {
  const { link } = props
  return (
    <div style={{ position: 'fixed' }}>
      {link &&
        <div>
          <iframe title='PDF Frame' className='iframe' id="evidence-frame" src={`https://entry.risika.dk/pdf${link}#zoom=80`} ></iframe>
          {/* <iframe title='PDF Frame' className='iframe' id="evidence-frame" src={`http://localhost:8014/pdf${link}#zoom=80`} ></iframe> */}
        </div>
      }
    </div>
  )
}
export default PdfViewer
