import axios from 'axios'
import { BACKEND_API_BASE_URL } from './config'

export const addToFinancialManual = (data, cvr, caseId, list, secret) => {
  return axios({
    method: 'post',
    url: `${BACKEND_API_BASE_URL}/post_financial_manual`,
    params: {
      secret: secret,
    },
    data: {
      data,
      cvr,
      caseId,
      list,
    },
  })
}
