import React from 'react'
import { connect } from 'react-redux'

import { TextField, FormControlLabel, Checkbox } from '@material-ui/core'
import { setUpdateTextField } from '../../../Redux/actions'
import numeral from 'numeral'
const notNumber = [
  'consolidated_statement',
  'local_id',
  'report_start',
  'report_end',
  'dateofapprovalofannualreport',
  'dateofgeneralmeeting',
  'informationontypeofsubmittedreport',
  'typeofauditorassistance',
  'classofreportingentity',
  'identificationnumbercvrofauditfirm',
  'descriptionofauditor',
  'nameandsurnameofauditor',
  'nameoffinancialinstitution',
  'nameOfLawFirm',
  'ifrs',
  'unit',
  'numberScale',
  'tax_expenses',
]
const CustomField = props => {
  const { field, classes, updateTextField, type, knownValue, index, color } = props
  const [value, setValue] = React.useState(knownValue || '')
  const [boolValue, setBoolValue] = React.useState(knownValue || false)
  const [numberValue, setNumberValue] = React.useState(knownValue || 0)

  React.useEffect(() => {
    updateTextField({ values: { name: field.key, value: boolValue }, index })
  }, [boolValue])
  React.useEffect(() => {
    // if (!notNumber.includes(field.key)) {
    //   console.log('number: ', field.key, 'value', value.length === 0)
    // }
    updateTextField({ values: { name: field.key, value: notNumber.includes(field.key) ? value : value.length === 0 ? numeral(0).format('0,0').split(',').join('') : numeral(value).format('0,0').split(',').join('') }, index })
  }, [value])

  const handleChange = event => {
    setValue(event.target.value)
    updateTextField({ values: { name: event.target.name, value: event.target.value }, index })
  }

  const handleChangeCheckbox = event => {
    setBoolValue(!boolValue)
    updateTextField({ values: { name: event.target.name, value: !boolValue }, index })
  }

  const handleChangeNumber = event => {
    setNumberValue(event.target.value)
    updateTextField({ values: { name: event.target.name, value: numeral(event.target.value).format('0,0').split(',').join('') }, index })
  }
  const handleChangeDate = event => {
    setValue(event.target.value)
    updateTextField({ values: { name: event.target.name, value: event.target.value !== 'Invalid date' ? event.target.value : null }, index })
  }

  return (
    <div style={{ textAlign: 'center' }}>
      { type === 'text' &&
      <TextField
        fullWidth
        id="outlined-name"
        label={field.name}
        className={classes.textField}
        value={value}
        style={color ? { backgroundColor: color } : null}
        name= {field.key}
        required={field.required}
        onChange={handleChange}
        onFocus={e => e.target.select()}
        margin="normal"
        variant="outlined"
        type='string'
      />}
      { type === 'number' &&
      <TextField
        fullWidth
        id="outlined-name"
        label={field.name}
        className={classes.textField}
        value={numeral(numberValue).format('0,0')}
        name= {field.key}
        style={color ? { backgroundColor: color } : null}
        required={field.required}
        onChange={handleChangeNumber}
        onFocus={e => e.target.select()}
        margin="normal"
        variant="outlined"
        type='string'
      />}
      { type === 'date' &&
    <TextField
      fullWidth
      type="date"
      id="date"
      label={field.name}
      required={field.required}
      className={classes.textField}
      style={color ? { backgroundColor: color } : null}
      name= {field.key}
      defaultValue={value && value !== 'Invalid date' ? value : ''}
      onChange={handleChangeDate}
      margin="normal"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
    />
      }
      { type === 'select' &&
      <TextField
        fullWidth
        id="outlined-select-currency-native"
        select
        required={field.required}
        value={value}
        className={classes.textField}
        style={color ? { backgroundColor: color } : null}
        onChange={handleChange}
        name={field.key}
        SelectProps={{
          native: true,
          MenuProps: {
            className: classes.menu,
          },
        }}
        helperText={field.name}
        margin="normal"
        variant="outlined"
      >
        {field.options.map(option => (
          <option key={option.name} value={option.text.toUpperCase()} >
            {option.text}
          </option>
        ))}
      </TextField>
      }
      { type === 'checkbox' &&
      <FormControlLabel
        control={
          <Checkbox checked={boolValue} onChange={handleChangeCheckbox} name={field.key} value={field.value} />
        }
        label={field.name}
      />
      }
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateTextField: (value, index) => dispatch(setUpdateTextField(value, index)),
})

export default connect(null, mapDispatchToProps)(CustomField)
