import React from 'react'
import { connect } from 'react-redux'
import { setAddToRedux } from '../../../Redux/actions'
import { InputBase } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Paste from '@material-ui/icons/FileCopy'

const Search = props => {
  const { classes, addToRedux, case_id } = props

  const setSearchValueAsync = async () => {
    navigator.clipboard.readText().then(text => addToRedux({ name: 'case_id', value: text }))
  }

  return (
    <React.Fragment>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          autoComplete='on'
          onChange={(e) => addToRedux({ name: 'case_id', value: e.target.value })}
          onFocus={e => e.target.select()}
          placeholder="Case Id..."
          value={case_id}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
      <Paste className={classes.pasteIcon} onClick={setSearchValueAsync} />
    </React.Fragment>
  )
}
const mapDispatchToProps = (dispatch) => ({
  addToRedux: (values) => dispatch(setAddToRedux(values)),
})

const mapStateToProps = state => ({
  case_id: state.Form.case_id,
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
