import React from 'react'
import HomePage from './Pages/HomePage'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

const theme = createMuiTheme({
  palette: {
    primary: { main: '#0A373D' },
    secondary: { main: '#FFCC89' },
  },
})

const App = props => {
  return (
    <ThemeProvider theme={theme}>
      <HomePage secret={window.location.search} />
    </ThemeProvider>
  )
}

export default App
