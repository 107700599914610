import React from 'react'
import { connect } from 'react-redux'
import { store } from '../../index'

import { fade, makeStyles } from '@material-ui/core/styles'

import { AppBar, Toolbar, IconButton, Typography, Button } from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'

import CustomSnackbar from './parts/CustomSnackbar'
import Search from './parts/Search'
import CVRSearch from './parts/CVRSearch'

import { validate } from '../../Utils/validate'
import { calculate } from '../../Utils/calculate'
import { setAddToRedux, setUpdatedTextFields } from '../../Redux/actions'

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  pasteIcon: {
    '& :hover': {
      cursor: 'pointer',
    },
  },
  button: {
    marginLeft: 'auto',
  },
  paper: {
    position: 'absolute',
    display: 'grid',
    borderRadius: '1rem',
  },
}))

const Header = props => {
  const classes = useStyles()
  const { setDialogOpen, message, setMessage, updateTextFields, saveToDB } = props
  const [open, setOpen] = React.useState(false)

  React.useEffect(() => {
    message !== '' && setOpen(true)
  }, [message])

  const handleClose = () => {
    setOpen(false)
  }

  // const handleSubmit = () => {
  //   // Validator does not work
  //   const validator = validate()
  //   setMessage(validator.message)
  //   if (validator.isValid) {
  //     const values = calculate()
  //     updateTextFields(values)
  //   }
  //   validator.isValid && setDialogOpen(true)
  // }

  return (
    <AppBar position="fixed">
      <CustomSnackbar open={open} handleClose={handleClose} message={message}/>
      <Toolbar>
        <Typography className={classes.title} variant="h6" noWrap>
        Financial Manual
        </Typography>
        <Search
          classes={classes}
        />
        <CVRSearch classes={classes}/>
        <Button onClick={saveToDB} variant="contained" color='secondary' className={classes.button}>
        Save
        </Button>
      </Toolbar>
    </AppBar>
  )
}

const mapDispatchToProps = (dispatch) => ({
  updateTextFields: (value, index) => dispatch(setUpdatedTextFields(value, index)),
})

export default connect(null, mapDispatchToProps)(Header)
