
export const setInitTextField = textField => {
  return {
    type: 'INIT_TEXT_FIELD',
    textField,
  }
}
export const setUpdatedTextFields = updatedTextField => {
  return {
    type: 'SET_UPDATED_TEXT_FIELD',
    updatedTextField,
  }
}
export const setUpdateTextField = action => {
  return {
    type: 'UPDATE_TEXT_FIELD',
    action,
  }
}
export const setAddToRedux = field => {
  return {
    type: 'ADD_TO_REDUX',
    field,
  }
}

export const setSearchValue = searchValue => {
  return {
    type: 'SET_SEARCH_VALUE',
    searchValue,
  }
}

export const discardLastForm = index => {
  return {
    type: 'DISCARD_LAST_FORM',
    index,
  }
}
