const initialState = {
  updatedTextField: {},
}
const textField = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_TEXT_FIELD':
      return {
        ...state,
        [action.action.index]: {
          ...state[action.action.index],
          [action.action.values.name]: action.action.values.value,
        },
      }
    case 'INIT_TEXT_FIELD':
      return {
        ...state,
        [action.textField[1]]: action.textField[0],
      }
    case 'ADD_TO_REDUX':
      return {
        ...state,
        [action.field.name]: action.field.value,
      }
    case 'SET_SEARCH_VALUE':
      return {
        ...state,
        searchValue: action.searchValue,
      }
    case 'SET_UPDATED_TEXT_FIELD':
      return {
        ...state,
        updatedTextField: action.updatedTextField,
      }
    case 'DISCARD_LAST_FORM':
      const { [action.index]: removedOne, ...rest } = state
      return rest
    default:
      return state
  }
}

export default textField
