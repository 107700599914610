import axios from 'axios'
import { BACKEND_API_BASE_URL } from './config'

export const getPdf = (caseId, secret) => {
  return axios
    .get(`${BACKEND_API_BASE_URL}/getanualreport`, {
      params: {
        caseId: caseId,
        secret: secret,
      },
    })
    .then(res => {
      return res
    })
    .catch(err => {
      // alert(err.response.data)
      return err
    })
}

export const getFinancialInformation = (caseId, secret) => {
  return axios.get(`${BACKEND_API_BASE_URL}/get_financial_information`, {
    params: {
      caseId: caseId,
      secret: secret,
    },
  })
    .then(res => {
      return res
    })
    .catch(err => {
      return err
    })
}

export const getAllByCVR = (cvr, secret) => {
  return axios.get(`${BACKEND_API_BASE_URL}/get_by_cvr`, {
    params: {
      cvr,
      secret,
    },
  })
    .then(res => {
      console.log(res)
      return res
    })
    .catch(err => {
      return err
    })
}
