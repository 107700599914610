import React from 'react'
import { connect } from 'react-redux'
import { setAddToRedux, discardLastForm } from '../Redux/actions'

import '../App.css'
import FormComponent from '../Components/Form/Form'
import PdfViewer from '../Components/PdfViewer/PdfViewer'
import Header from '../Components/Header/Header'
import SubmitDialog from '../Components/Dialog/SubmitDialog'

import { Fab } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'

import { getPdf, getFinancialInformation } from '../Services/getData'
import { addToFinancialManual } from '../Services/addToDB'

const HomePage = props => {
  const { Form, caseId, secret, discardForm } = props
  // Form State
  const [link, setLink] = React.useState('')
  const [financialInformation, setFinancialInformation] = React.useState([])
  // Dialog State
  const [dualForm, setDualForm] = React.useState(false)
  const [discardedForm, setDiscardedForm] = React.useState(null)
  const [dialogOpen, setDialogOpen] = React.useState(false)
  // Header state
  const [message, setMessage] = React.useState('')

  /**
   * Fetches the PDF depending on case_id
   */
  React.useEffect(() => {
    setFinancialInformation([])
    setLink('')
    caseId && caseId.length >= 3 ? fetchData(caseId) : console.log()
  }, [caseId])

  // Todo: Reset the redux and local states when user searches for new Anual statement
  const fetchData = (caseId) => {
    getPdf(`${caseId.toUpperCase()}`, secret)
      .then(link => {
        getFinancialInformation(`${caseId.toUpperCase()}`, secret)
          .then(financialInfo => {
            setFinancialInformation(financialInfo.data)
            setLink(link.data)
          })
      })
  }

  const saveToDB = () => {
    if (Form[0]) {
      addToFinancialManual(Form[0], Form[0].local_id, Form.case_id, `http://regnskaber.virk.dk${link}`, secret).then(res => {
        if (res.data.error) {
          setMessage(res.data.error.split('\n')[0])
        } else {
          setMessage(res.data.success)
        }
      })
    }
    if (Form[1]) {
      addToFinancialManual(Form[1], Form[1].local_id, Form.case_id, `http://regnskaber.virk.dk${link}`, secret).then(res => {
        if (res.data.error) {
          setMessage(res.data.error.split('\n')[0])
        } else {
          setMessage(res.data.success)
        }
      })
    }
    setDialogOpen(false)
  }

  const addAditionalForm = () => {
    const transferValues = ['local_id', 'report_start', 'report_end', 'dateofapprovalofannualreport', 'dateofgeneralmeeting', 'informationontypeofsubmittedreport', 'typeofauditorassistance', 'classofreportingentity', 'identificationnumbercvrofauditfirm', 'descriptionofauditor', 'nameoffinancialinstitution', 'nameOfLawFirm', 'ifrs', 'unit', 'numberScale']
    if (!financialInformation[1]) {
      const temp = [financialInformation[0], financialInformation[0]]
      setFinancialInformation(temp)
      setDualForm(true)

      Object.keys(temp[1]).map(x => {
        if (!transferValues.includes(x)) {
          temp[1][x] = ''
        }
      })
    }
  }

  const removeAditionalForm = () => {
    if (financialInformation[1]) {
      const temp = financialInformation
      temp.pop()
      setFinancialInformation(temp)
      setDualForm(false)
      discardForm(1)
    }
  }

  /**
   * Todo: Check For DECIMAL ERRORS IN THE FINANCIAL STATEMENT
   */

  window.onbeforeunload = function () {
    return 'Are you sure you want to leave?'
  }

  return (
    <div>
      <Header
        saveToDB={saveToDB}
        setMessage={setMessage}
        message={message}
        fetchPDF={(caseId) => fetchData(caseId)}
        consolidatedStatements={financialInformation ? financialInformation.consolidated_statement : false}
        setDialogOpen={setDialogOpen}
      />
      {link &&
        <div style={{ marginTop: '3rem', position: 'relative' }}>
          {
            !dualForm
              ? <Fab onClick={() => addAditionalForm()} color="secondary" aria-label="add" className style={{ position: 'absolute', right: '0', top: '1rem', height: '2rem', width: '2rem' }} ><Add /></Fab>
              : <Fab onClick={() => removeAditionalForm()} aria-label="remove" className style={{ position: 'absolute', right: '0', top: '1rem', height: '2rem', width: '2rem', color: 'red' }} ><Remove /></Fab>
          }
          <div className={financialInformation.length > 1 ? 'dualForm' : ''}>
            {
              financialInformation[0]
                ? financialInformation.map((financialStats, i) => (
                  <FormComponent key={i} index={i} financialInformation={financialStats} />
                ))
                : <FormComponent index={0} />
            }
          </div>
          <PdfViewer link={link} />
          <SubmitDialog
            financialInformation={financialInformation}
            dialogOpen={dialogOpen}
            setDualForm={setDualForm}
            setDiscardedForm={setDiscardedForm}
            dualForm={dualForm}
            discardedForm={discardedForm}
            setDialogOpen={setDialogOpen}
            saveToDB={saveToDB}
          />
        </div>
      }

    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  addToRedux: (values) => dispatch(setAddToRedux(values)),
  discardForm: (index) => dispatch(discardLastForm(index)),
})

const mapStateToProps = state => ({
  Form: state.Form,
  caseId: state.Form.case_id,
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
