import React from 'react'
import { connect } from 'react-redux'
import { setInitTextField } from '../../Redux/actions'

import { makeStyles } from '@material-ui/core/styles'
import CustomField from './parts/CustomTextField'
import textFields from '../../textfields.json'

const useStyles = makeStyles(theme => ({
  container: {
    margin: '1rem',
    float: 'right',
    width: '50%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dense: {
    marginTop: theme.spacing(2),
  },
  menu: {
    width: 200,
  },
  discarded: {
    display: 'none',
  },
}))

const Form = props => {
  const classes = useStyles()
  const { setTextField, financialInformation, index, discarded } = props

  // This might cause some inconsistencies at some point
  React.useEffect(() => {
    !financialInformation &&
    setTextField([textFields.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.key]: '',
      }
    }, {}), index])
  }, [])
  return (
    <form className={discarded !== index ? classes.container : classes.discarded} autoComplete="off">
      {
        textFields.map((field, i) => (
          <CustomField
            key={`${field.key} ${i}`}
            color={field.color}
            field={field}
            classes={classes}
            index={index}
            type={field.type}
            knownValue={financialInformation ? financialInformation[field.key] : ''}
          />
        ))
      }

    </form>
  )
}

const mapDispatchToProps = (dispatch) => ({
  setTextField: (textField, index) => dispatch(setInitTextField(textField, index)),
})

export default connect(null, mapDispatchToProps)(Form)
