import React from 'react'
import { connect } from 'react-redux'
import { InputBase, IconButton } from '@material-ui/core'
import { Search } from '@material-ui/icons'

import EntriesDialog from './EntriesDialog'

import { setAddToRedux } from '../../../Redux/actions'
import { getAllByCVR } from '../../../Services/getData'

const CVRSearch = props => {
  const { classes, addToRedux } = props
  const [cvrValue, setCvrValue] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [values, setValues] = React.useState([])

  const handleClick = () => {
    getAllByCVR(cvrValue, window.location.search)
      .then(res => {
        setValues(res.data)
        setOpen(true)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleSelect = (data) => {
    addToRedux(data)
    setOpen(false)
  }

  return (
    <React.Fragment>
      <div className={classes.search}>
        <InputBase
          autoComplete='on'
          placeholder="CVR..."
          onChange={(e) => setCvrValue(e.target.value)}
          value={cvrValue}
          type='number'
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>
      {values.length && open && <EntriesDialog addToRedux={handleSelect} open={open} setOpen={setOpen} values={values}/> }
      <IconButton onClick={handleClick} variant="contained" color='secondary'><Search className={classes.searchHover} />Search</IconButton>
    </React.Fragment>
  )
}

const mapDispatchToProps = (dispatch) => ({
  addToRedux: (values) => dispatch(setAddToRedux(values)),
})

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(CVRSearch)
