import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import { discardLastForm } from '../../Redux/actions'

import { Button, Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import Form from '../Form/Form'

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: 'center',
  },
  dualForm: {
    display: 'flex',
  },
  singleForm: {
    margin: '0 auto',
  },
  deleteButton: {
    height: '4rem',
  },
}))

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const SubmitDialog = props => {
  const classes = useStyles()
  const {
    dialogOpen,
    financialInformation,
    setDualForm,
    setDiscardedForm,
    dualForm,
    discardedForm,
    setDialogOpen,
    saveToDB,
    updatedTextField,
    discardFormRedux,
  } = props

  React.useEffect(() => {
    financialInformation.length > 1 && setDualForm(true)
  }, [])

  const discardForm = index => {
    setDiscardedForm(index)
    setDualForm(false)
    discardFormRedux(index)
  }

  return (
    <Dialog fullScreen open={dialogOpen} onClose={() => setDialogOpen(false)} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => setDialogOpen(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
          Review the form once more and press Save to update the database.
          </Typography>
          <Button onClick={saveToDB} variant="contained" color='secondary' >
          save
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.dualForm}>
        {
          Object.keys(updatedTextField).map((i) => (
            <React.Fragment key={i}>
              { dualForm &&
                <IconButton onClick={() => discardForm(i)} name={i} id={i} aria-label="delete" className={classes.deleteButton}>
                  <DeleteIcon name={i} id={i} fontSize="large" />
                </IconButton>
              }
              <Form discarded={discardedForm} id={i} index={i} financialInformation={updatedTextField[i]} />
            </React.Fragment>
          ))
        }
      </div>
    </Dialog>
  )
}
const mapStateToProps = state => ({
  updatedTextField: state.Form.updatedTextField,
})

const mapDispatchToProps = (dispatch) => ({
  discardFormRedux: (values) => dispatch(discardLastForm(values)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SubmitDialog)
